import './textarea.scss'
import React from 'react';

interface InputProps {
    id: string,
    name: string,
    handleChange?: React.ChangeEventHandler<HTMLInputElement>,
    handleOnBlur?: React.FocusEventHandler<HTMLInputElement>,
    handleOnFocus?: React.FocusEventHandler<HTMLInputElement>
    hasError?: boolean,
    textError?: string
  }

class Textarea extends React.Component<any, any> {

    constructor(props: InputProps) {
        super(props);
    }


    render() {
        return (
            <>
                <label className="dgr-textfield-outlined">
                    <textarea id={this.props.id} onChange={this.props.handleChange} ></textarea>
                    <span>{this.props.name}</span>
                </label>
            </>
        )
    }
}

export default Textarea;