export class FormEntitie {
    public name: string = "";
    public lastName: string= "";
    public email: string= "";
    public phone: string= "";
    public cedula: string= "";
    public description: string= "";
    public idCareer: string= "";
    public idCampus: string= "";
    
   
}