import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import Textarea from '../../components/textarea/textarea';
import Select from '../../components/select/select';
import { ChangeEvent, ChangeEventHandler } from 'react';
import React, { Component } from 'react'
import Input from '../../components/inputs/input';
import { optionSelect } from '../../components/select/input-select.interface';
import './form-inscription.scss'
import { FormEntitie } from '../../entities/form.entitie';

class FormInscription extends React.Component<any, any> {
  formEntitie = new FormEntitie();

  constructor(props: any) {
    super(props);

    this.state = {
      name: '',
      lastName: '',
      email: '',
      phone: '',
      cedula: '',
      description: '',
      idCareer: '',
      idCampus: '',
      optionsCareers: this.careers.map(x => { return { value: x.guid_career, label: x.name } }),
      optionsCampus: [],
      hasErrorRepeatEmail: false,
    }

  }



  changeOptionsCampus = (e: ChangeEvent<any>) => {
    this.setDataSelects(e);
    const id = e.target.value;
    const career = this.careers.find(x => x.guid_career === id);
    if (career) {
      const optionsCampus = career.campus.map<optionSelect>(x => { return { value: x.guid_campus, label: x.name } })
      this.setState({
        optionsCampus: optionsCampus
      })
    }
  }

  careers = [
    {
      guid_career: '1',
      name: 'Abogacia',
      campus: [
        {
          guid_campus: 'a',
          name: 'Asunción'
        },
        {
          guid_campus: 'b',
          name: 'Ciudad del este'
        }
      ]
    },
    {
      guid_career: '2',
      name: 'Medicina',
      campus: [
        {
          guid_campus: 'a',
          name: 'Asunción'
        }
      ]
    }
    ,
    {
      guid_career: '3',
      name: 'Teatro',
      campus: [
        {
          guid_campus: 'c',
          name: 'Areguá'
        }
      ]
    }
  ]

  saveData = (e: ChangeEvent<any>) => {
    this.setState({ [e.target.id]: e.target.value });
  }

  setDataSelects = (e: ChangeEvent<any>) => {
    this.setState({ [e.target.id]: e.target.value });
  }

  checkError = (e: ChangeEvent<any>) => {
    this.setState({ hasErrorRepeatEmail: this.state.email !== e.target.value });
  }

  render() {
    
    const {name,
    lastName,
    email,
    phone,
    cedula,
    description,
    idCareer,
    idCampus} = this.state;
    const enabled = name.length > 0 && lastName.length > 0 && email.length > 0 && phone.length > 0 && cedula.length > 0 && 
                    description.length > 0 && idCareer.length > 0 && idCampus.length > 0;

    return (
      <>
        <form className="container mt-3">
          <div className="card">
            <div className="card-body">
              <div className="card-title-text">
                <h5 className="card-title mb-2">Inscribite</h5>
                     Completá con los datos solicitados para poder ingresar
                  </div>

              <div className="row">
                <div className="col-md-6 pb-3">
                  <Input name="Nombre" id="name" type="text" handleChange={this.saveData} ></Input>
                </div>
                <div className="col-md-6 pb-3">
                  <Input name="Apellido" id="lastName" type="text" handleChange={this.saveData}></Input>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pb-3">
                  <Input name="Email" id="email" type="email" handleChange={this.saveData}></Input>
                </div>
                <div className="col-md-6 pb-3">
                  <Input name="Repetí tu email" id="newEmail" type="email" handleOnBlur={this.checkError}
                    hasError={this.state.hasErrorRepeatEmail} textError="El email esta mal"></Input>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pb-3">
                  <Input name="Cedula" id="cedula" type="text" handleChange={this.saveData} ></Input>
                </div>
                <div className="col-md-6 pb-3">
                  <Input name="Teléfono" id="phone" type="number" handleChange={this.saveData}></Input>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pb-3">
                  <Select id="idCareer" label="Carrera" options={this.state.optionsCareers}
                    handleChange={this.changeOptionsCampus}
                    defaultValue="Seleccioná tu carrera"> </Select>
                </div>
                <div className="col-md-6 pb-3">
                  <Select id="idCampus" label="Sede" options={this.state.optionsCampus}
                    handleChange={this.setDataSelects}
                    defaultValue="Seleccioná tu sede"> </Select>
                </div>
              </div>

              <Textarea name="Comentarios" id="description" handleChange={this.saveData}></Textarea>
              <Button type="submit" variant="uninorte" size="lg" disabled={!enabled}> Enviar!</Button>
            </div>
          </div>
        </form>
      </>
    )
  }
}

export default FormInscription;


