import React from 'react';
import { inputSelect } from './input-select.interface';
import './select.scss'

  class Select extends React.Component<inputSelect, inputSelect> {

    constructor(props: inputSelect) {
      super(props);
      this.state = {
        label : this.props.label,
        options: this.props.options
      }
    }

  
    render() {

      const selectOptions = this.props.options.map(({ value, label }) => (
        <option value={value} key={value}> {label}</option>
      ));

      const options =  this.props.options && this.props.options.length > 0 ? selectOptions : null; 
      return (
        <div className="dgr-select-outlined" >
          <select id={this.props.id} className="select-text" onChange={this.props.handleChange}>
          <option value="" selected disabled hidden>{this.props.defaultValue}</option>
            {options}
              </select>
           <label className="select-label">{this.props.label} </label>
        </div>
      ) 
    }
  }

export default Select;