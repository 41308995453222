import React from 'react';
import './input.scss'

interface InputProps {
  id: string,
  name: string,
  type: string,
  placeholder?: string,
  handleChange?: React.ChangeEventHandler<HTMLInputElement>,
  handleOnBlur?: React.FocusEventHandler<HTMLInputElement>,
  handleOnFocus?: React.FocusEventHandler<HTMLInputElement>
  hasError?: boolean,
  textError?: string
}

class Input extends React.Component<InputProps, any> {

  constructor(props: InputProps) {
    super(props);
  }


  render() {
    const error = this.props.hasError ? <div className="kui-textfield-outlined-helper-line">
                                          <div className="kui-textfield-outlined-helper-text__error">
                                            {this.props.textError}
                                          </div>
                                        </div> : null;

    return (
      <div className="kui-form-container  ">
        <label className={this.props.hasError ? 'kui-textfield-outlined kui-textfield-outlined__error mb-0' : 'kui-textfield-outlined mb-0'} htmlFor="namee">
          <input name={this.props.name} id={this.props.id} type={this.props.type || "text"} placeholder={this.props.placeholder}
            onChange={this.props.handleChange} onBlur={this.props.handleOnBlur} onFocus={this.props.handleOnFocus} />
          <span>{this.props.name} </span>
        </label>
        
        {error}
      </div>
    )
  }
}

export default Input;